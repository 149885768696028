import { Component } from 'react';
import Products from './components/products';
import Banner from './components/banner';
import Layout from './components/layout';
import Navbar from './components/navbar';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Loader from "react-loader-spinner";

const styles = {
  autocomplete: {
    margin: 'auto',
  },
  dropdowsContainer: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  spinner: {
    margin: 'auto',
    textAlign: 'center',
    marginTop: '40px'
  }
}


class App extends Component {
  state = {
    products: [],
    car: [],
    allProducts: [],
    categories: [],
    spinner: true
  }

  async componentDidMount() {
    this.getAllProducts();
    this.getAllCategories();
  }

  async getAllProducts () {
    const response = await fetch(`https://farma7.avanticasadesoftware.com/products.php`);
    const json = await response.json();
    this.setState({ products: json, allProducts: json, spinner: false });
  }

  async getAllCategories () {
    const response = await fetch(`https://farma7.avanticasadesoftware.com/categories.php`);
    const json = await response.json();
    this.setState({ categories: json });
  } 

  onTagsChangeCategories = (event, values) => {
    this.setState({ spinner: true })
    if (values) {
      this.onSearchRenderProductsForCategories(values)
    } else {
      setTimeout(() => {
        this.setState({ products: this.state.allProducts, spinner: false })
      }, 1000);
    }
  }

  async onSearchRenderProductsForCategories(values) {
    const { cat_codigo } = values
    const response = await fetch(`https://farma7.avanticasadesoftware.com/products.php?cat_code=${cat_codigo}`);
    const json = await response.json();

    setTimeout(() => {
      this.setState({ products: json, spinner: false });
    }, 1000);
  }

  onTagsChangeProducts = (event, values) => {
    this.setState({ spinner: true })
    if (values) {
      this.onSearchRenderProducts(values)
    } else {
      setTimeout(() => {
        this.setState({ products: this.state.allProducts, spinner: false })
      }, 1000);
    }
  }

  async onSearchRenderProducts(values) {
    const { DESCRIPCION, COMACT } = values
    const response = await fetch(`https://farma7.avanticasadesoftware.com/products.php?description=${DESCRIPCION}&comact=${COMACT}`);
    const json = await response.json();

    setTimeout(() => {
      this.setState({ products: json, spinner: false });
    }, 1000);
  }

  
  deleteItemCar = (item) => {
    let dataNewCar = this.state.car.filter(x => x.CODIGO !== item.CODIGO)
    this.setState({ car: dataNewCar });
  }

  AddToCar = (product) => {
    const { car } = this.state
    if (car.find(x => x.CODIGO === product.CODIGO)) {
      const newCar = car.map(x => x.CODIGO === product.CODIGO
        ? ({
          ...x,
          quantity: x.quantity + 1
        })
        : x)
      return this.setState({ car: newCar })
    }
    return this.setState({
      car: this.state.car.concat({
        ...product,
        quantity: 1
      })
    })
  }

  render() {
    return (
      <div>
        <Navbar
          car={this.state.car}
          deleteItemCar={this.deleteItemCar}
        />
        <Banner />
        <Layout>
          <div style={styles.dropdowsContainer}>
            <Autocomplete
              style={styles.autocomplete}
              disablePortal
              id="combo-box-demo-2"
              options={this.state.categories ? this.state.categories : []}
              autoHighlight
              sx={{ width: 400 }}
              onChange={this.onTagsChangeCategories}
              getOptionLabel={(option) => `${option.cat_descripcion}`}
              renderInput={(params) => <TextField {...params} label="Buscar categorias" />}
            />
            <Autocomplete
              style={styles.autocomplete}
              disablePortal
              id="combo-box-demo"
              options={this.state.products ? this.state.products : []}
              autoHighlight
              sx={{ width: 400 }}
              onChange={this.onTagsChangeProducts}
              getOptionLabel={(option) => `${option.DESCRIPCION}`}
              renderInput={(params) => <TextField {...params} label="Buscar productos" />}
            />
          </div>
          {
            this.state.spinner === true &&
            <div style={styles.spinner}>
              <Loader
                type="Puff"
                color="#00BFFF"
                height={100}
                width={100}
                timeout={3000} //3 secs
              />
            </div>
          }
          {
            this.state.spinner === false &&
            <Products
              AddToCar={this.AddToCar}
              products={this.state.products}
            />
          }
        </Layout>
      </div>
    )
  }
}

export default App;