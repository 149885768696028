import { Component } from 'react';
import Button from './button';

const styles = {
  products: {
    border: 'solid 1px #eee',
    boxShadow: '0 5px 5px rgb(0, 0, 0, 0.1)',
    maxWidth: '100%',
    padding: '10px 15px',
    borderRadius: '5px'
  },
  img: {
    width: '100%'
  },
  details:  {
    padding: 0,
    margin: 0
  },
  description: {
    fontWeight: 'bold',
    fontSize: '18px',
    marginTop: '5px'
  }
}

class Product extends Component {
  render() {
    const { product, AddToCar } = this.props;
    return (
      <div style={styles.products}>
        <img style={styles.img} alt={product.CODIGO} src={product.url_imagen}></img>
        <p style={styles.description}>{product.DESCRIPCION}</p>
        <div>
          <p style={styles.details}> COP: {product.COP}</p>
          <p style={styles.details}> USD: {product.USD}</p>
          <p style={styles.details}> Existencia: {product.EXIST}</p>
        </div>
        <Button onClick={() => AddToCar(product)}>
          Add to car
        </Button>
      </div>
    )
  }
}

export default Product;