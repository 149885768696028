import { Component } from 'react';
import Product from './product';

class Products extends Component {
  render() {
    const { products, AddToCar } = this.props;
    
    return (
      <div className="productsContainer">
        {products.map(prod =>
          <Product
            AddToCar={AddToCar}
            key={prod.CODIGO}
            product={prod}
          />
        )}
      </div>
    )
  }
}

export default Products;