import { Component } from 'react';

const styles = {
  carDetail: {
    backgroundColor: '#FFF',
    position: 'absolute',
    marginTop: 30,
    boxShadow: '1px 5px 5px rgb(0,0,0,0.3)',
    borderRadius: '5px',
    width: '300px',
    right: 50,
  },
  ul: {
    margin: 0,
    padding: 0,
    maxHeight: '210px',
    overflowY: 'scroll'
  },
  product: {
    listStyleType: 'none',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '25px 20px',
    borderBottom: 'solid 1px #aaa',
  },
  img: {
    padding: '10px'
  },
  ws: {
    float: 'right',
    textDecoration: 'none'
  },
  wsImage: {
    width: '40px',
    padding: '4px'
  },
  close: {
    float: 'right',
    fontWeight: 'bolder',
    '&:hover':{
      cursor: 'pointer'
    } 
  },
  quantity: {
    float: 'left',
    fontWeight: 'bolder'
  }
}

class CarDetail extends Component {

  render() {
    const { car, deleteItemCar } = this.props
    const clientNumber = '584120653454'

    let products = car.map((car) => {
      return {
        Codigo:`${car.CODIGO} ` + ` ${car.DESCRIPCION}`,
        Cantidad: `${car.quantity}`
      }
    });

    const productsToJson = JSON.stringify(products);
    const productsToWs = productsToJson.replace(/[\[\]{}""']+/g,' ')

    return (
      <div style={styles.carDetail}>
        <ul style={styles.ul}>
          {car.map(x =>
            <li style={styles.product} key={x.CODIGO}>
              <img style={styles.img} alt={x.CODIGO} src={'./products/oxacilina.jpg'} width='50' height='32' />
              <div>
                <div>{x.DESCRIPCION}</div>
                <div onClick={() => deleteItemCar(x)} style={styles.close}>X</div>
                <div style={styles.quantity}>Cantidad: {x.quantity}</div> 
              </div>
            </li>
          )}
        </ul>
        {
          car.length > 0 &&
          <a style={styles.ws} href={"https://api.whatsapp.com/send?phone=" + clientNumber + "&text=Hola, me gustaría obtener información de: " + productsToWs}>
            Enviar pedido vía <img style={styles.wsImage} src="./ws.png"></img>
          </a>
        }
      </div>
    )
  }
}

export default CarDetail;